const pages = {
  service: [
    {
      title: 'Mobile App Development',
      href: '/mobile-app-development',
    },
    {
      title: 'Web Development',
      href: '/web-development',
    },
    {
      title: 'Web Design',
      href: '/web-design',
    },
    {
      title: 'Training',
      href: '/training',
    },
    {
      title: 'Co-Working Space',
      href: '/coworkingspace',
    },
    {
      title: 'Online Platform',
      href: '/onlineplatform',
    },
  ],
  about: [
    {
      title: 'About Us',
      href: '/about',
    },
  ],
  blog: [
    {
      title: 'Blog',
      href: '/blog',
    },
  ],
  seminar: [
    {
      title: 'Events',
      href: '/seminar-event',
    },
  ],
  career: [
    {
      title: 'Careers',
      href: '/career-opening',
    },
  ],
  mentor: [
    {
      title: ' Become a mentor',
      href: '/onlineplatform/mentor',
    },
  ],
  create: [
    {
      title: ' Create a course',
      href: '/onlineplatform/create',
    },
  ],
};

export default pages;
